<script setup lang="ts"></script>

<template>
  <div class="h-8 w-full bg-black bg-opacity-30">
    <div class="container mx-auto flex items-end justify-end pr-5 text-white">
      Created by
      <a class="jagulogo-jagu-line" href="https://jagu.cz/" target="_blank">
        Jagu s.r.o.
      </a>
    </div>
  </div>
</template>

<style scoped>
.jagulogo-jagu-line:before {
  font-size: 150%;
  margin: 0 5px;
  top: 5px;
  position: relative;
}
</style>
