import revive_payload_client_bJnr1GtwUr from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.2_@types+node@20.17.16_eslint@9.18.0_rollup@4.31.0_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_DHVSXkBsgW from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.2_@types+node@20.17.16_eslint@9.18.0_rollup@4.31.0_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zkWuzahC4F from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.2_@types+node@20.17.16_eslint@9.18.0_rollup@4.31.0_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_qZq2fip719 from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.31.0_vite@6.0.11_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_nbyZl82V4l from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.2_@types+node@20.17.16_eslint@9.18.0_rollup@4.31.0_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_WFgbhU2dNN from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.2_@types+node@20.17.16_eslint@9.18.0_rollup@4.31.0_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_3Fr8G8irfL from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.2_@types+node@20.17.16_eslint@9.18.0_rollup@4.31.0_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5nOtySoEdM from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.2_@types+node@20.17.16_eslint@9.18.0_rollup@4.31.0_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/dotykacka/designex-group/dg-client-zone/.nuxt/components.plugin.mjs";
import prefetch_client_5Np1Z5mpaQ from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.2_@types+node@20.17.16_eslint@9.18.0_rollup@4.31.0_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_eQHs0InlHB from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.31.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _01_sentry_client_2rh1pDAIty from "/builds/dotykacka/designex-group/dg-client-zone/plugins/01.sentry.client.ts";
import _02_firebase_client_4c9KdheLb8 from "/builds/dotykacka/designex-group/dg-client-zone/plugins/02.firebase.client.ts";
import _03_firebaseui_client_2DeIH0F1by from "/builds/dotykacka/designex-group/dg-client-zone/plugins/03.firebaseui.client.ts";
import _04_functions_client_8C4voHcNsM from "/builds/dotykacka/designex-group/dg-client-zone/plugins/04.functions.client.ts";
import _05_qrcode_client_nixZao7HuG from "/builds/dotykacka/designex-group/dg-client-zone/plugins/05.qrcode.client.ts";
import _06_vue_3_sanitize_client_YBWgHnW9Av from "/builds/dotykacka/designex-group/dg-client-zone/plugins/06.vue-3-sanitize.client.ts";
export default [
  revive_payload_client_bJnr1GtwUr,
  unhead_DHVSXkBsgW,
  router_zkWuzahC4F,
  _0_siteConfig_qZq2fip719,
  payload_client_nbyZl82V4l,
  navigation_repaint_client_WFgbhU2dNN,
  check_outdated_build_client_3Fr8G8irfL,
  chunk_reload_client_5nOtySoEdM,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5Np1Z5mpaQ,
  i18n_eQHs0InlHB,
  _01_sentry_client_2rh1pDAIty,
  _02_firebase_client_4c9KdheLb8,
  _03_firebaseui_client_2DeIH0F1by,
  _04_functions_client_8C4voHcNsM,
  _05_qrcode_client_nixZao7HuG,
  _06_vue_3_sanitize_client_YBWgHnW9Av
]