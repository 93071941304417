<script setup lang="ts"></script>

<template>
  <div
    class="bg-header-black flex h-screen w-screen flex-col items-center justify-center text-3xl text-primary"
  >
    <img
      class="w-full max-w-[90vw] md:!w-[550px]"
      src="/img/png/refresh-bistro-logo.png"
    />
    <span class="mt-32 uppercase">{{ $t('login.loading') }}...</span>
  </div>
</template>

<style scoped></style>
