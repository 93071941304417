
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as cloudsHHKFLebAzZMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/admin/clouds.vue?macro=true";
import { default as flyerhXD07OYLgVMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/admin/flyer.vue?macro=true";
import { default as _91id_93dK31r7K3pOMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/admin/incomingTransactions/[id].vue?macro=true";
import { default as indexrRgHfu0Mc1Meta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/admin/incomingTransactions/index.vue?macro=true";
import { default as _91id_93cL765kAukLMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/admin/news/[id].vue?macro=true";
import { default as create9oUvUNxTAWMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/admin/news/create.vue?macro=true";
import { default as indexSwr8QbwM9fMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/admin/news/index.vue?macro=true";
import { default as _91id_93lMITtaaCxBMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/admin/users/[id].vue?macro=true";
import { default as indexZ102o9JTN1Meta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/admin/users/index.vue?macro=true";
import { default as docs8ESVyip0q5Meta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/docs.vue?macro=true";
import { default as flyerSRmyWzXm2xMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/flyer.vue?macro=true";
import { default as indexqkWM5j2hECMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/index.vue?macro=true";
import { default as loginw9HDeGSrL8Meta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/login.vue?macro=true";
import { default as _91id_93rNqoD1ngNFMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/news/[id].vue?macro=true";
import { default as indexoobOCWkURaMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/news/index.vue?macro=true";
import { default as indexiojZx3c74XMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/register/index.vue?macro=true";
import { default as verificationMOnH77rGSwMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/register/verification.vue?macro=true";
import { default as indexyqQMJWeBMyMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/settings/index.vue?macro=true";
import { default as topUpGAoImCJfIOMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/topUp.vue?macro=true";
import { default as transactionHistoryBmIKD8AdxFMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/transactionHistory.vue?macro=true";
import { default as _91profileId_9373Tp0ieLuJMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/user/profile/[profileId].vue?macro=true";
import { default as createbGy4Zc4oUCMeta } from "/builds/dotykacka/designex-group/dg-client-zone/pages/user/profile/create.vue?macro=true";
export default [
  {
    name: "admin-clouds___cs",
    path: "/admin/cloudy",
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/admin/clouds.vue")
  },
  {
    name: "admin-flyer___cs",
    path: "/admin/vyhody-karty",
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/admin/flyer.vue")
  },
  {
    name: "admin-incomingTransactions-id___cs",
    path: "/admin/prichozi-transakce/:id()",
    meta: _91id_93dK31r7K3pOMeta || {},
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/admin/incomingTransactions/[id].vue")
  },
  {
    name: "admin-incomingTransactions___cs",
    path: "/admin/prichozi-transakce",
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/admin/incomingTransactions/index.vue")
  },
  {
    name: "admin-news-id___cs",
    path: "/admin/novinky/:id()",
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/admin/news/[id].vue")
  },
  {
    name: "admin-news-create___cs",
    path: "/admin/novinky/pridat",
    meta: create9oUvUNxTAWMeta || {},
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/admin/news/create.vue")
  },
  {
    name: "admin-news___cs",
    path: "/admin/novinky",
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/admin/news/index.vue")
  },
  {
    name: "admin-users-id___cs",
    path: "/admin/uzivatele/:id()",
    meta: _91id_93lMITtaaCxBMeta || {},
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users___cs",
    path: "/admin/uzivatele",
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/admin/users/index.vue")
  },
  {
    name: "docs___cs",
    path: "/docs",
    meta: docs8ESVyip0q5Meta || {},
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/docs.vue")
  },
  {
    name: "flyer___cs",
    path: "/vyhody-karty",
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/flyer.vue")
  },
  {
    name: "index___cs",
    path: "/",
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/index.vue")
  },
  {
    name: "login___cs",
    path: "/prihlaseni",
    meta: loginw9HDeGSrL8Meta || {},
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/login.vue")
  },
  {
    name: "news-id___cs",
    path: "/novinky/:id()",
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/news/[id].vue")
  },
  {
    name: "news___cs",
    path: "/novinky",
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/news/index.vue")
  },
  {
    name: "register___cs",
    path: "/register",
    meta: indexiojZx3c74XMeta || {},
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/register/index.vue")
  },
  {
    name: "register-verification___cs",
    path: "/registrace/verifikace",
    meta: verificationMOnH77rGSwMeta || {},
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/register/verification.vue")
  },
  {
    name: "settings___cs",
    path: "/nastaveni-uctu",
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/settings/index.vue")
  },
  {
    name: "topUp___cs",
    path: "/dobit-kartu",
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/topUp.vue")
  },
  {
    name: "transactionHistory___cs",
    path: "/historie-transakci",
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/transactionHistory.vue")
  },
  {
    name: "user-profile-profileId___cs",
    path: "/ucet/profil/:profileId()",
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/user/profile/[profileId].vue")
  },
  {
    name: "user-profile-create___cs",
    path: "/ucet/profil/vytvorit",
    meta: createbGy4Zc4oUCMeta || {},
    component: () => import("/builds/dotykacka/designex-group/dg-client-zone/pages/user/profile/create.vue")
  }
]