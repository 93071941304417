<script lang="ts" setup>
import { getAuth } from 'firebase/auth'
import { computed } from 'vue'

const { t } = useI18n()

const auth = getAuth()
const userStore = useUser()
function logout() {
  auth.signOut()
}

const localePath = useLocalePath()

const isAdmin = userStore.user.value?.admin

const items = computed(() => {
  return isAdmin
    ? [
        {
          text: t('pages.users.page-title'),
          link: localePath({ name: 'admin-users' }),
        },
        {
          text: t('pages.news.page-title'),
          link: localePath({ name: 'admin-news' }),
        },
        {
          text: t('pages.incoming-transactions.page-title'),
          link: localePath({ name: 'admin-incomingTransactions' }),
        },
        {
          text: t('pages.clouds.page-title'),
          link: localePath({ name: 'admin-clouds' }),
        },
        {
          text: t('pages.flyer.page-title'),
          link: localePath({ name: 'admin-flyer' }),
        },
      ]
    : [
        {
          text: t('pages.overview.page-title'),
          link: '/',
        },
        {
          text: t('pages.topUp.page-title'),
          link: localePath({ name: 'topUp' }),
        },
        {
          text: t('pages.settings.page-title'),
          link: localePath({ name: 'settings' }),
        },
        {
          text: t('pages.news.page-title'),
          link: localePath({ name: 'news' }),
        },
        {
          text: t('pages.transaction-history.page-title'),
          link: localePath({ name: 'transactionHistory' }),
        },
        {
          text: t('pages.flyer.page-title'),
          link: localePath({ name: 'flyer' }),
        },
      ]
})

defineEmits(['link-clicked'])

const route = useRoute()
function isActive(link: string) {
  if (link === '/' || link === '') {
    return route.path === '/'
  }
  return route.path.toString().startsWith(link)
}
</script>

<template>
  <div
    class="flex h-fit min-w-80 flex-col justify-start gap-4 border-2 border-primary bg-white bg-opacity-100 px-8 pb-8 pt-4 shadow-xl"
  >
    <SideMenuItem
      v-for="item in items"
      :key="item.text"
      :text="item.text"
      :link="item.link"
      :icon="item.icon"
      :active="isActive(item.link)"
      @click="$emit('link-clicked', item.link)"
    />
    <UiButton class="mx-auto mt-12" size="md" type="primary" @click="logout">
      {{ $t('login.logout') }}
    </UiButton>
  </div>
</template>
