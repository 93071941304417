<script setup lang="ts">
import { getAuth } from 'firebase/auth'
import type { User } from 'firebase/auth'
import * as Sentry from '@sentry/vue'

const authLoading = ref(true)
const auth = getAuth()
const userStore = useUser()
const { apiError } = useErrors()
const route = useRoute()
const { $registerUserInSentry } = useNuxtApp()
const localePath = useLocalePath()

try {
  localStorage['nuxt-color-mode'] = 'light'
} catch (err) {
  console.debug('localStorage access denied')
  navigateTo(localePath({ name: 'login' }))
}

const { t } = useI18n()

useHead({
  title: 'ReFresh Bistro - ' + t('base.title'),
})

const initialCheck = ref(true)
async function checkUser(user: User | null) {
  if (user) {
    authLoading.value = true
    try {
      await userStore.updateUser()
      const finalRoute = userStore.checkUserRoute(route.fullPath)
      if (finalRoute !== route.fullPath) {
        await navigateTo(finalRoute)
      }
      if (!import.meta.env.DEV) {
        $registerUserInSentry()
      }
      authLoading.value = false
    } catch (err) {
      if (!import.meta.env.DEV) {
        Sentry.captureException(err)
      }
      apiError(err, '', 'login.error')
      if (auth.currentUser) {
        auth.signOut()
      }
      authLoading.value = false
    }
  } else {
    userStore.user.value = null
    if (initialCheck.value && route.path !== localePath({ name: 'login' })) {
      try {
        localStorage.setItem('remember-url', route.path)
      } catch (err) {
        console.debug('localStorage access denied')
      }
    }
    await navigateTo(localePath({ name: 'login' }))
    authLoading.value = false
  }
  initialCheck.value = false
}

auth.onAuthStateChanged((user) => {
  checkUser(user)
})
</script>

<template>
  <div>
    <ScreenLoad v-if="authLoading" />
    <NuxtLayout v-else>
      <NuxtPwaManifest />
      <NuxtPage />
      <UiNotifications />
    </NuxtLayout>
  </div>
</template>

<style>
.min-h-footer {
  min-height: calc(100vh - 114px);
}
</style>
