import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { sentry },
  } = useRuntimeConfig()
  const router = useRouter()
  const { vueApp } = nuxtApp

  const config = useRuntimeConfig()
  if (!sentry.clientDsn || import.meta.env.DEV) {
    console.warn('Sentry DSN not set, skipping Sentry initialization')
    return
  }

  if (sentry.environment != 'local') {
    Sentry.init({
      app: [vueApp],
      dsn: sentry.clientDsn,
      environment: sentry.environment,
      release: config.public.appVersion,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          networkDetailAllowUrls: [
            /.*europe-west3-dtk-sheets-sync.cloudfunctions.net.*/,
            /.*europe-west3-designex-group-sync-364809.cloudfunctions.net*./,
          ],
        }),
      ],
      tracePropagationTargets: [
        'localhost',
        'designex-group-sync-364809.web.app',
        'karta.refreshbistro.cz',
        /^\//,
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
    })
  }

  function registerUserInSentry() {
    const { user } = useUser()
    if (user.value) {
      Sentry.setUser({
        id: user.value.id,
        name:
          user.value.name && user.value.surname
            ? user.value.name + ' ' + user.value.surname
            : 'Unknown',
        email: user.value.email,
      })
    }
  }

  return {
    provide: {
      registerUserInSentry,
    },
  }
})
