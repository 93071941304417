<script lang="ts" setup></script>

<template>
  <div class="bg-defaultBg h-screen w-full overflow-auto bg-cover bg-center">
    <UiHeader is-login />
    <div class="min-h-footer overflow-auto bg-black bg-opacity-30">
      <slot />
    </div>
    <UiFooter />
  </div>
</template>

<style></style>
