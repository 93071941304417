<script setup lang="ts">
import { getAuth } from 'firebase/auth'
import { Icons } from '~/enums/icons'

const props = defineProps<{
  isLogin?: boolean
}>()

const auth = getAuth()

function logout() {
  auth.signOut()
}

function routeHome() {
  return useUser().routeToUserRoot()
}

const userEmail = ref('')
auth.onAuthStateChanged((user) => {
  userEmail.value = user?.email ?? ''
})
</script>

<template>
  <div class="bg-header-black z-50 flex h-[82px] items-center px-5 py-3">
    <div class="container mx-auto flex justify-between">
      <NuxtLink class="cursor-pointer" @click="routeHome">
        <img
          class="h-[52px] w-[225px]"
          src="~/assets/png/refresh-bistro-logo.png"
        />
      </NuxtLink>
      <template v-if="userEmail">
        <div
          class="my-auto items-center gap-4 text-lg text-primary"
          :class="props.isLogin ? 'flex' : 'hidden xl:flex'"
        >
          <div class="hidden sm:block">
            {{ userEmail }}
          </div>
          <UiIconButton
            size="30"
            :name="Icons.LEAVE"
            :translated-tooltip="$t('login.logout')"
            @click="logout"
          />
        </div>
      </template>
      <div v-if="!isLogin" class="flex items-center xl:hidden">
        <slot name="sidebar" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
