<script lang="ts" setup>
const props = defineProps<{
  text: string
  link: string
  active: boolean
}>()

const base = 'text-xl py-1 px-2 w-max underline-link '
const cls = computed(
  () => `${base}${props.active ? 'font-bold is-active' : ''}`
)
</script>

<template>
  <NuxtLink :to="link" class="group" :class="cls">
    {{ text.toUpperCase() }}
  </NuxtLink>
</template>

<style scoped>
.underline-link {
  position: relative;
  display: inline-block;
}

.underline-link::after {
  content: '';
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 0;
  height: 2px;
  background-color: currentColor;
  transform-origin: left center;
  transform: scaleX(0);
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  will-change: transform;
}

.underline-link:hover::after,
.underline-link.is-active::after {
  transform: scaleX(1);
}

.underline-link.is-active::after {
  transition: none;
}
</style>
