export const Icons = {
  CLOSE: 'ic:sharp-close',
  CHECK: 'mdi:check',
  ADD: 'gg:add',
  EDIT: 'mdi:pencil',
  DELETE: '',
  CHEVRON_LEFT: 'fa6-solid:chevron-left',
  CHEVRON_RIGHT: 'fa6-solid:chevron-right',
  CHEVRON_UP: 'fa6-solid:chevron-up',
  CHEVRON_DOWN: 'fa6-solid:chevron-down',
  LEAVE: 'pepicons-pop:leave',
  INFO: 'material-symbols:info',
  ARROW_UP: 'mingcute:arrow-up-fill',
  ARROW_BACK: 'ic:baseline-arrow-back-ios',
  DOT: 'oui:dot',
  COPY: 'gg:copy',
  FILTER: 'material-symbols:filter-list',

  TRANSACTION_HISTORY: 'humbleicons:exchange-horizontal',
  USER: 'mdi:user',
}
