<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import { Icons } from '~/enums/icons'

const props = defineProps<{
  options: { label: string; value: any }[]
  buttonClass?: string
  optionsClass?: string
  optionClass?: string
  addNewLink?: string
  placement?: 'bottom' | 'top' | 'right' | 'left'
}>()

const selected = defineModel<{ label: string; value: any }>()

const showMenu = ref(false)

if (!selected.value) {
  selected.value = props.options[0]
}

const { anchor, floating, floatingStyles } = useUiFloating({
  strategy: 'absolute',
  placement: props.placement,
})

const target = ref<HTMLElement | null>(null)
onClickOutside(target, (_) => {
  showMenu.value = false
})
</script>

<template>
  <Listbox v-slot="{ open }" v-model="selected">
    <div ref="target" class="relative z-20">
      <ListboxButton
        ref="anchor"
        class="z-20"
        :class="
          cn(
            'bg-header-black relative flex w-full items-center gap-4 px-4 py-2 text-left text-primary',
            props.buttonClass
          )
        "
        as="button"
        @click="showMenu = !showMenu"
      >
        <span class="block truncate">{{ selected?.label }}</span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        />
        <Icon
          size="15"
          class="min-w-[15px] text-white transition-all"
          :name="Icons.CHEVRON_DOWN"
          :class="{ 'rotate-180': open }"
        />
      </ListboxButton>

      <div
        v-show="showMenu"
        ref="floating"
        :style="floatingStyles"
        class="w-full"
      >
        <div class="w-fit overflow-clip">
          <transition
            enter-active-class="motion-safe:transition duration-300"
            enter-from-class="opacity-0 translate-y-1"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="motion-safe:transition duration-200"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-1"
          >
            <ListboxOptions
              static
              :class="[
                cn(
                  'bg-header-black w-full min-w-fit text-base',
                  props.optionsClass
                ),
              ]"
            >
              <div class="max-h-64 overflow-auto">
                <ListboxOption
                  v-for="option in props.options"
                  v-slot="{ active, selectedOption }"
                  :key="option.label"
                  :value="option"
                  class="flex py-1 pl-[1px]"
                  @click="showMenu = false"
                >
                  <button
                    :class="
                      cn(
                        [
                          active ? ' text-primary' : '',
                          'relative w-full select-none p-2 px-4 text-left',
                        ],
                        props.optionClass
                      )
                    "
                  >
                    <span
                      :class="[
                        selectedOption ? 'font-medium' : 'font-normal',
                        'block truncate',
                      ]"
                    >
                      {{ option.label }}
                    </span>
                  </button>
                  <div
                    class="m-2 flex items-center justify-center"
                    @click.stop
                    @click="showMenu = false"
                  >
                    <slot name="item-append" :item="option.value" />
                  </div>
                </ListboxOption>
                <div
                  v-if="addNewLink"
                  class="flex items-center justify-center p-2 normal-case text-primary"
                >
                  <UiTooltip :label="$t('cardholder.create')">
                    <NuxtLink :to="addNewLink" @click="showMenu = false">
                      <Icon :name="Icons.ADD" size="35" />
                    </NuxtLink>
                  </UiTooltip>
                </div>
              </div>
            </ListboxOptions>
          </transition>
        </div>
      </div>
    </div>
  </Listbox>
</template>
